import Vue from "vue";
import Vuex from "vuex";
import { createStore } from "vuex";

import actions from "./actions";
import mutations from "./mutations";

import global from "./modules/global";

const store = createStore({
  // 不要在发布环境下启用严格模式！严格模式会深度监测状态树来检测不合规的状态变更——请确保在发布环境下关闭严格模式，以避免性能损失。
  strict: process.env.NODE_ENV !== "production",
  state: {},
  mutations: mutations,
  actions: actions,
  modules: {
    global,
  }
})

export default store;

// Vue.use(Vuex);

// export default new Vuex.Store({
  // 不要在发布环境下启用严格模式！严格模式会深度监测状态树来检测不合规的状态变更——请确保在发布环境下关闭严格模式，以避免性能损失。
  // strict: process.env.NODE_ENV !== "production",
  // state: {},
  // mutations: mutations,
  // actions: actions,
  // modules: {
  //   h5,
  //   web,
  //   user,
  //   global,
  //   activity
  // }
// });
