<template>
    <div id="footer">
        <div class="text-box">
            <span class="cp">CLOUDMATRIX HOLDINGS LIMITED</span>
            <div class="bt" v-if="!isMobile">
                商務合作郵箱：support@yulimeta.com | 反饋郵箱：feedback@yulimeta.com | 聯係方式：+852 66111658
            </div>
            <div class="mbt" v-else>
                <div>商務合作郵箱：support@yulimeta.com</div>
                <div style="margin-top: 6px;" class="mbt-row">
                    <span style="margin-right: 8px;">反饋郵箱：feedback@yulimeta.com</span>
                    <span>聯係方式：+852 66111658</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { IsMobile } from '@/utils/utils.js';
export default {
    name: "FooterPage",
    components: {
        
    },
    data() {
        return {
            isMobile: false
        };
    },
    mounted() {
        this.$nextTick(() => {
        
        });
        this.isMobile = IsMobile();
        console.log(this.isMobile);
        // window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        // window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        
    },
};
</script>
<style lang="less" scoped>
    #footer{
        z-index: 1;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 125px;
        background-color: #222;
        .text-box{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #B8BABA;
            font-family: Josefin Sans;
            text-align: left;
            .cp{
                user-select: none;
                margin-bottom: 6px;
                font-size: 16px;
                font-weight: bold;
            }
            .bt{
                user-select: text;
                font-size: 16px;
                font-weight: 400;
            }
            .mbt{
                user-select: text;
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
    @media screen and (max-width: 765px) {
        #footer{
            height: 95px;
            box-sizing: border-box;
            .text-box{
                height: 100%;
                position: static;
                transform: unset;
                align-items: flex-start;
                box-sizing: border-box;
                padding: 12px 25px;
                .cp{
                    font-size: 15px;
                    margin-bottom: 10px;
                }
                .bt{
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }
</style>
  