<template>
  <div>
    <Index></Index>
  </div>
</template>

<script>
import Index from "@/components/Index";
export default {
  components: {
    Index,
  },
  data() {
    return {
      isMobile: false,
      isWebp: false,
    };
  },
  setup() {},
  created() {
    // this.check_webp_feature()
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      // this.$router.replace("/mobile");
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },

  methods: {},
};
</script>

<style></style>
