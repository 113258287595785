import { createRouter, createWebHistory } from "vue-router";

import index from "@/views/Pc/index";

/**
 * 创建路由对象
 */
const routes_PC = [
  {
    path: "/",
    meta: {
      title: "Yulimeta",
    },
    component: index,
  },
  {
    path: "/PrivacyPolicy", //隐私政策
    meta: {
      title: "Metaport Yulimeta Privacy Policy",
    },
    component: () => import("@/views/Pc/documents/PrivacyPolicy.vue"),
  },
  {
    path: "/Agreement", //用户协议
    meta: {
      title: "End User License Agreement",
    },
    component: () => import("@/views/Pc/documents/Agreement.vue"),
  },
  {
    path: "/howToPlay", //净化地脉介绍
    meta: {
      title: "Yuliverse",
    },
    component: () => import("@/views/H5/howToPlay"),
  },
  {
    path: "/chipHowToPlay", //芯片玩法介绍
    meta: {
      title: "Yuliverse",
    },
    component: () => import("@/views/H5/chipHowToPlay"),
  },
  {
    path: "/gainEntryExplain", //yuli增益词条介绍
    meta: {
      title: "Yuliverse",
    },
    component: () => import("@/views/H5/gainEntryExplain"),
  },
  {
    path: "/attributes", //芯片属性详情
    meta: {
      title: "Yuliverse",
    },
    component: () => import("@/views/H5/attributesDetail"),
  },
  {
    path: "/finest", //限时活动
    meta: {
      title: "Yuliverse",
    },
    component: () => import("@/views/H5/finestForum"),
  },
];

const routes =[
    ...routes_PC,
]
//创建路由
const router = createRouter({
  history: createWebHistory(),
  routes, //定义的路由
});
// 路由拦截操作
router.beforeEach((to, from, next) => {
  // 拦截标题配置
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;
