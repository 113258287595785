<template>
    <div id="app">
      <!-- 底图背景 -->
      <div class="background"></div>
      <!-- 内容盒 -->
      <div class="content-box">
        <!-- 图文盒 -->
        <div class="box">
            <div class="title-icon"></div>
            <div class="label">
                Role-Playing Adventure with LBS Chain!
            </div>
            <div class="content" v-if="!hideContent">
                Step into the Yuliverse, where the delicate balance of light and shadow hangs by a thread. 

                You are Yuli, a character on a mission to cleanse the world.

                Your journey transcends the digital realm. Guided by our exclusive Yuliverse Map, venture offline and explore the world of AD2023. 

                Uncover the secrets of your city, stumble upon uncharted territories, and gradually unfold an intricate storyline intertwined with your real-life explorations. 

                This is an adventure that spills into your reality.
            </div>
            <div class="content" v-else>
                {{ hideContentText }} <span class="click" @click="showConetnt" v-show="allContentText">More></span>
            </div>
        </div>
        <!-- 角色 -->
        <!-- <div class="role">
            <img class="img" src="@/assets/images/index/d-role.png">
        </div> -->
      </div>
      <!-- 底部 -->
      <Footer></Footer>
    </div>
</template>
  
<script>
import Footer from '@/components/Index/components/Footer/Footer';
export default {
    name: "IndexPage",
    components: {
        Footer
    },
    data() {
        return {
            hideContent: false,
            hideContentText: "Step into the Yuliverse, where the delicate balance of light and shadow hangs by a thread. \n You are Yuli, a character on a mission to cleanse the world...",
            allContentText: "Step into the Yuliverse, where the delicate balance of light and shadow hangs by a thread. \n You are Yuli, a character on a mission to cleanse the world\nYour journey transcends the digital realm. Guided by our exclusive Yuliverse Map, venture offline and explore the world of AD2023. \n Uncover the secrets of your city, stumble upon uncharted territories, and gradually unfold an intricate storyline intertwined with your real-life explorations. \nThis is an adventure that spills into your reality.",
        };
    },
    mounted() {
        this.$nextTick(() => {
            if(window.innerHeight <= 800)this.hideContent = true;
        });
        // window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        // window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        showConetnt(){
            this.hideContentText = this.allContentText;
            this.allContentText = false;
        }
    },
};
</script>
<style lang="less" scoped>
@import url("~@/common/less/webpbg.less");
#app {
    width: 100%;
    min-width: 1200px;
}
.background{
    z-index: 0;
    position: fixed;
    width: 100%;
    // height: calc(100vw * (1075 / 1920));
    height: calc(100vh - 120px);
    background-image: url("~@/assets/images/index/bg.png");
    background-size: 100% 100%;
}
.content-box{
    width: 100%;
    height: calc(100vh - 125px);
    position: relative;
    .box{
        position: absolute;
        top: 0.8612rem;//211px
        right: 0.9142rem;//224
        z-index: 2;
        .title-icon{
            // width: 1.7755rem;//435
            // height: 0.9755rem;// 239
            width: 640px;
            height: 245px;
            // margin-left: -0.1469rem;//-36
            background-image: url("~@/assets/images/index/title.png");
            background-size: 100% 100%;
            margin-bottom: 0.0653rem;//16
        }
        .label{
            color: #ffffff;
            max-width: 2.85rem;
            height: 0.5306rem;
            font-size: 0.2367rem;
            font-weight: 600;
            font-family: Josefin Sans Bold;
            text-align: left;
            line-height: 0.2648rem;
            margin-bottom: 0.0653rem;
        }
        .content{
            max-width: 3.3265rem;
            height: fit-content;
            color: #FFFFFF;
            font-size: 0.0979rem;
            font-weight: 500;
            font-family: Josefin Sans;
            text-align: left;
            line-height: 0.1714rem;
            transition: all .3s;
            .click{
                color: #FC75FF;
                font-weight: 400;
                font-family: Josefin Sans;
            }
        }
    }
    .role{
        width: 3.4693rem;
        height: 3.804rem;
        // background-image: url("~@/assets/images/index/d-role.png");
        // background-size: 100% 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        .img{
            width: 100%;
            height: 100%;
        }
    }
}
// 正常手机宽度上限
@media screen and (max-width: 765px) {
    #app{
        width: 100%;
        height: 100vh;
        
        min-width: unset;
    }
    .background{
        width: 100%;
        height: 100%;
        background-image: url("~@/assets/images/index/bg-mobile.png");
        background-size: cover;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
    .content-box{
        width: 100%;
        height: calc(100% - 90px);
        position: relative;
        box-sizing: border-box;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        .box{
            position: static;
            margin: 0 auto;
            width: 350px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 25px;
            padding-top: 45px;
            .title-icon{
                width: 204px;
                height: 78.09px;
                background-image: url("~@/assets/images/index/mobile-title.png");
                background-size: 100% 100%;
            }
            .label{
                color: #ffffff;
                max-width: 254px;
                height: 48px;
                font-size: 20px;
                font-weight: 700;
                font-family: Josefin Sans Bold;
                text-align: center;
                line-height: 24px;
                margin-bottom: 8px;
            }
            .content{
                max-width: 815px;
                color: #FFFFFF;
                font-size: 14px;
                font-weight: 400;
                font-family: Josefin Sans;
                text-align: center;
                line-height: 20px;
            }
        }
        .role{
            width: 303px;
            height: 332px;
            position: static;
            bottom: 0;
            transform: unset;
        }
    }
}
@media screen and (min-height: 825px) and (max-height: 900px){
    .content-box{
        .role{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
@media screen and (height: 667px) {
    .content-box{
        .box{
            padding: 0 15px;
            padding-top: 45px;
        }
        .role{
            width: 242px;
            height: 265px;
            position: static;
            // left: 50%;
            // transform: translateX(-50%);
        }
    }
}
</style>
  