let isSupportWebp = function () {
  try {
    return (
      document
        .createElement("canvas")
        .toDataURL("image/webp", 0.5)
        .indexOf("data:image/webp") === 0
    );
  } catch (err) {
    return false;
  }
};
export const isWebp = isSupportWebp();
export const webBg = {
  mounted(el) {
    if (isWebp) {
      let str = el.getAttribute("class").split(" ");
      str.push("webpa");
      el.setAttribute("class", str.join(" "));
    }
  },
};
export const webp = {
  mounted(el) {
    if (isWebp) {
      // let src = el.getAttribute("data-src");
      // el.src = src+'.webp';
      // el.setAttribute('src',`${src}.webp`)
      // el.onerror = () => {
      //     el.src = src
      // }
    }
  },
};


