export const Debounce = (fn, t) => {
  const delay = t || 300
  let timeout
  return function () {
    const context = this
    const args = arguments
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null
        fn.apply(context, args)
      }, delay)
    }
  }
}

export const IsMobile = () => {
  return navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )?.length > 0;
}

/**
 * 文件下载
 */
export function DownloadFile(url, responseFileType = 'json') {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = responseFileType;
    
    xhr.onload = () => {
      if(xhr.status === 200) {
        resolve(xhr.response);
      } else {
        reject(new Error('Failed to download'));
      }
    }

    xhr.onerror = () => {
      reject(new Error('Failed to download'));
    }

    xhr.send();
  })
}