const globalModule = {
  namespaced: true,
  state: {
    init: false,
    isApp: false,
    isAndorid: 0,
    statusBarHeight: 0,
    resume: 0,
    appUserInfo: null,
    tokenIds:"",
    languageEnv: "en",
    languagePackage: {},
  },
  getters: {},
  mutations: {
    SET_INIT: (state, init) => {
      state.init = init;
    },
    SET_IS_APP: (state, isApp) => {
      state.isApp = isApp;
    },
    SET_IS_ANDROID:(state, isAndorid)=>{  //安卓10兼容
      state.isAndorid = isAndorid
    },
    SET_STATUS_BAR_HEIGHT: (state, statusBarHeight) => {
      state.statusBarHeight = statusBarHeight;
    },
    SET_RESUME: (state, resume) => {
      state.resume += 1;
    },
    SET_APP_USERINFO: (state, appUserInfo) => {
      state.appUserInfo = appUserInfo;
    },
    SET_TOKEN_IDS:(state,tokenIds)=>{
      state.tokenIds = tokenIds
    },
    SET_LANGUAGE_ENV:(state, languageEnv) => {
      state.languageEnv = languageEnv;
    },
    SET_LANGUAGE_PACKAGE:(state, languagePackage) => {
      state.languagePackage = languagePackage;
    },
  },
  actions: {
    onShow({ commit, state }, data) {
      console.log(state, "state");
      const resume = state.resume + 1;
      commit("SET_RESUME", resume);
    },
    globalInit({ commit, state }, data) {
      const { init, isApp,isAndroid } = data;
      commit("SET_INIT", init);
      commit("SET_IS_APP", isApp);
      commit("SET_IS_ANDROID", isAndroid);
    },
    statusBarHeightInit({ commit, state }, data) {
      const { statusBarHeight } = data;
      commit("SET_STATUS_BAR_HEIGHT", statusBarHeight);
    },
    setAppUserInfo({ commit, state }, data) {
      console.log("SET_APP_USERINFO", data);
      commit("SET_APP_USERINFO", data);
    },
    setTokenIds({ commit, state }, data){
      if(!data||!data.length){
        commit("SET_TOKEN_IDS", '空');
      }else{
        commit("SET_TOKEN_IDS", data);
      }
      // commit("SET_TOKEN_IDS", data);
    },
    setLanguageEnv({ commit, state }, data){
      if(data){
        commit("SET_LANGUAGE_ENV", data);
      }
    },
    setLanguagePackage({ commit, state }, data){
      if(data){
        commit("SET_LANGUAGE_PACKAGE", data);
      }
    }
  }
};

export default globalModule;
